<script setup lang="ts">
import IconAmex from '@/shared/assets/icons/IconAmex.svg'
import IconApple from '@/shared/assets/icons/IconApple.svg'
import IconCallChat from '@/shared/assets/icons/IconCallChat.svg'
import IconDiscover from '@/shared/assets/icons/IconDiscover.svg'
import IconJCB from '@/shared/assets/icons/IconJCB.svg'
import IconMastercard from '@/shared/assets/icons/IconMastercard.svg'
import IconUser from '@/shared/assets/icons/IconUser.svg'
import IconVideo from '@/shared/assets/icons/IconVideo.svg'
import IconVisa from '@/shared/assets/icons/IconVisa.svg'
import { ROUTE_NAV, LINK_TO_DOWNLOAD_APP } from '@/shared/config'
import { cookiesAccess } from '@/shared/lib/cookiesAccess'
import { urlCache } from '@/shared/lib/urlCache'
import AveolaLogo from '@/shared/public/landing/logo.svg'
import IconSpecialOne from '@/shared/public/landing/special-1.svg'
import IconSpecialTwo from '@/shared/public/landing/special-2.svg'
import IconSpecialThree from '@/shared/public/landing/special-3.svg'
import IconTabBar from '@/shared/public/landing/tab-bar.svg'
import { ButtonWhite } from '@/shared/ui/button'
import { LinkToSupportEmail } from '@/shared/ui/email'

import { authEntity } from '@/entities/auth'
import { geoEntity } from '@/entities/geo'
import { retentionEntity } from '@/entities/retention'

import { authFeature } from '@/features/auth'

// import { FooterLinks } from '@/widgets/footer'
// import { CookiesAccessModal } from '@/widgets/modal/cookies-access/modal'
// import { CookiesAccessTopbar } from '@/widgets/modal/cookies-access/toolbar'
// import { DownloadAppModal } from '@/widgets/modal/download-app-modal'

const FooterLinks = defineAsyncComponent(() => import('@/widgets/footer/ui/FooterLinks.vue'))
const DownloadAppModal = defineAsyncComponent(
  () => import('@/widgets/modal/download-app-modal/ui/DownloadAppModal.vue'),
)
const CookiesAccessModal = defineAsyncComponent(
  () => import('@/widgets/modal/cookies-access/modal/ui/CookiesAccessModal.vue'),
)
const CookiesAccessTopbar = defineAsyncComponent(
  () => import('@/widgets/modal/cookies-access/toolbar/ui/CookiesAccessTopbar.vue'),
)

const LANDING_TYPE_FOR_ANALYTICS = 1
const ANALYTICS_TIMEOUT_DELAY_MS = 200
// Here's the data that helps index site properly

useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#3C3C35',
    },
  ],
  link: [
    {
      rel: 'canonical',
      href: 'https://aveola.live',
    },
  ],
})

const $modal = useModal()
const localePath = useLocalePath()
const { isDesktop } = useLayout()
const { $analytics, $facebook } = useNuxtApp()
const authAutoLogin = authFeature.model.useAuthAutoLogin()

const state = reactive({
  countryReady: false,
  isUSA: false,
  isCY: false,
})

onBeforeMount(() => {
  urlCache.urlSet('enter')
  retentionEntity.model.sourcePablicInit()
})

onMounted(async () => {
  const userProps = $analytics.userPropsGet({
    viewtype: window.innerWidth >= 1024 ? 'desktop' : 'mobile',
  })
  const searchParams = urlCache.searchParamsGet('enter')
  if (searchParams) {
    const partnerId = searchParams.p
    if (partnerId) userProps.partner_id = partnerId
    const enterProps = authEntity.model.enterProps()
    $analytics.userProps({ ...userProps, ...enterProps })
    const params = {
      partner_id: partnerId || '',
      utm_source: searchParams.utm_source || '',
      utm_medium: searchParams.utm_medium || '',
      utm_campaign: searchParams.utm_campaign || '',
      utm_term: searchParams.utm_term || '',
      utm_content: searchParams.utm_content || '',
    }
    $analytics.event('landing_shown', params)
  }

  await authAutoLogin(true)
  if (cookiesAccess.needShow) $modal.open('cookies-access-tollbar')
  //
  state.isUSA = await geoEntity.model.isUSA()
  state.isCY = await geoEntity.model.isCY()
  state.countryReady = true
})

function onClickButtonSignup(index: number) {
  $analytics.event('landing_signup_pressed', {
    button: index,
    landing_type: LANDING_TYPE_FOR_ANALYTICS,
  })
  $facebook.event('Search', {})
}
function onDownloadApp() {
  $analytics.event('redirect_button_pressed', {
    entry: 'index_page',
  })
  if (isDesktop.value) $modal.open('download-app')
  else {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      navigateTo(LINK_TO_DOWNLOAD_APP, { external: true, open: { target: '_self' } })
    }, ANALYTICS_TIMEOUT_DELAY_MS)
  }
}
</script>

<template>
  <div class="overflow-x-hidden main-bg">
    <div class="landing-background">
      <div class="max-w-640 mx-auto">
        <header class="w-full px-16 py-8 flex justify-between items-center">
          <AveolaLogo />
          <nav class="flex justify-between items-center gap-12">
            <div
              class="apple-button h-40 cursor-pointer w-40 flex justify-center rounded-12 items-center"
              target="_blank"
              @click="onDownloadApp"
            >
              <IconApple class="-translate-y-2" />
            </div>
            <NuxtLink
              :to="localePath(ROUTE_NAV.authWelcome)"
              class="flex justify-center h-40 w-40 items-center rounded-12 profile-button"
              @click="onClickButtonSignup(0)"
            >
              <IconUser />
            </NuxtLink>
          </nav>
        </header>

        <section
          class="h-[calc(100svh-56px)] w-full flex flex-col justify-end items-center main-banner px-20"
        >
          <ButtonWhite
            size="44"
            class="flex justify-center items-center w-full mb-8"
            @click="$router.push(localePath(ROUTE_NAV.authWelcome))"
          >
            <IconCallChat class="mr-4" fill="#FF006E" />{{
              $t('landing.main_section.button')
            }}</ButtonWhite
          >

          <div
            class="h-44 w-full bg-red cursor-pointer mb-16 flex justify-center items-center text-button-medium text-white rounded-12 download-button"
            @click="onDownloadApp"
          >
            <span>{{ $t('landing.main_section.button_download') }}</span>
          </div>

          <NuxtLink :to="localePath(ROUTE_NAV.authWelcome)">
            <IconTabBar />
          </NuxtLink>
        </section>
      </div>
    </div>
    <div class="max-w-640 mx-auto">
      <section class="pt-40 pb-32 px-20">
        <p class="flex justify-center items-center mb-8">
          <IconVideo class="mr-8" />
          <span class="text-h3-bold text-white">{{ $t('landing.main_section.title') }}</span>
        </p>

        <p class="text-gray-800 text-center mb-12">
          {{ $t('landing.main_section.subtitle') }}
        </p>

        <div class="w-full py-24 px-16 rounded-16 bg-gray-900 mb-12">
          <p class="mb-8 text-h3-bold text-white">{{ $t('landing.main_section.list_title') }}</p>
          <p class="text-h6-medium text-gray-700">
            {{ $t('landing.main_section.list_description') }}
          </p>
        </div>

        <div class="w-full py-24 px-16 rounded-16 mb-12 bg-gray-900">
          <p class="mb-8 text-h3-bold text-white">
            {{ $t('landing.main_section.reg_block_title') }}
          </p>
          <p class="text-h6-medium text-gray-700 whitespace-pre-line">
            {{ $t('landing.main_section.reg_block_subtitle') }}
          </p>
        </div>

        <ButtonWhite
          size="44"
          class="flex justify-center items-center w-full"
          @click="$router.push(localePath(ROUTE_NAV.authWelcome))"
        >
          <IconCallChat class="mr-4" fill="#FF006E" />{{
            $t('landing.main_section.button')
          }}</ButtonWhite
        >
      </section>

      <section class="px-20 mb-12">
        <p class="gradient-text text-h3-bold text-center mb-20">
          {{ $t('landing.second_section.title') }}
        </p>

        <div class="pt-24 rounded-16 bg-gray-900 mb-24">
          <div class="mb-24 flex w-full justify-center items-center">
            <span class="text-white text-h4-bold">{{ $t('landing.second_section.subtitle') }}</span>
          </div>
          <img
            src="/landing/iphone-1.webp"
            width="auto"
            height="auto"
            alt="Meet someone new"
            class="px-20 mx-auto"
            loading="lazy"
          />
        </div>
      </section>

      <section class="w-full px-20 mb-12">
        <div class="rounded-16 pt-24 bg-gray-900">
          <p class="text-white text-center mb-24 text-h4-bold">
            {{ $t('landing.third_section.title') }}
          </p>
          <img
            src="/landing/iphone-2.webp"
            width="auto"
            height="auto"
            alt="Chat with new friends"
            class="px-20 mx-auto"
            loading="lazy"
          />
        </div>
      </section>

      <section class="w-full px-20 mb-64">
        <div class="rounded-16 pt-24 bg-gray-900">
          <p class="text-white text-center mb-24 text-h4-bold">
            {{ $t('landing.fourth_section.title') }}
          </p>
          <img
            src="/landing/iphone-3.webp"
            width="auto"
            height="auto"
            alt="Call history"
            class="px-20 mx-auto"
            loading="lazy"
          />
        </div>
      </section>

      <section class="w-full px-20 mb-56 pt-32">
        <p class="text-center text-h2-bold mb-16 gradient-text">
          {{ $t('landing.fifth_section.title') }}
        </p>
        <img
          src="/landing/girl-1.webp"
          width="auto"
          height="auto"
          alt="Find new friends"
          class="px-20 mx-auto"
          loading="lazy"
        />
        <h2 class="text-white text-h3-bold mb-8">{{ $t('landing.fifth_section.subtitle') }}</h2>
        <p class="text-h4-medium text-gray-700 mb-12">
          {{ $t('landing.fifth_section.text_primary') }}
        </p>
      </section>

      <section class="w-full px-20 mb-56 pt-32">
        <img
          src="/landing/girl-2.webp"
          width="auto"
          height="auto"
          alt="Random Video Chat"
          loading="lazy"
          class="pl-4 pr-64 mb-24"
        />
        <h2 class="text-white text-h3-bold mb-8">{{ $t('landing.six_section.title') }}</h2>
        <p class="text-h4-medium text-gray-700">
          {{ $t('landing.six_section.subtitle') }}
        </p>
      </section>

      <section class="w-full px-20 mb-56 pt-32">
        <img
          src="/landing/girl-3.webp"
          width="auto"
          height="auto"
          alt="Live Video Chat"
          loading="lazy"
          class="pl-4 pr-64 mb-24"
        />
        <h2 class="text-white text-h3-bold mb-8">{{ $t('landing.seventh_section.title') }}</h2>
        <p class="text-h4-medium text-gray-700 whitespace-pre-line">
          {{ $t('landing.seventh_section.text') }}
        </p>
      </section>

      <section class="w-full px-20 mb-56 pt-32">
        <img
          src="/landing/girl-4.webp"
          width="auto"
          height="auto"
          alt="International Video Chat"
          loading="lazy"
          class="pl-4 pr-64 mb-24"
        />
        <h2 class="text-white text-h3-bold mb-8">{{ $t('landing.eight_section.title') }}</h2>
        <p class="text-h4-medium whitespace-pre-line text-gray-700">
          {{ $t('landing.eight_section.text') }}
        </p>
      </section>

      <section class="w-full px-20 mb-32">
        <h2 class="text-center mb-8 gradient-text text-h3-bold">
          {{ $t('landing.what_is_special_section.title') }}
        </h2>

        <div class="p-16 rounded-12 bg-gray-900 flex justify-start items-center mb-8">
          <IconSpecialOne class="w-44 h-44 mr-16" />
          <div>
            <p class="text-white text-h4-bold mb-2">
              {{ $t('landing.what_is_special_section.block_one_title') }}
            </p>
            <p class="text-p-medium text-gray-800">
              {{ $t('landing.what_is_special_section.block_one_subtitle') }}
            </p>
          </div>
        </div>
        <div class="p-16 rounded-12 bg-gray-900 flex justify-start items-center mb-8">
          <IconSpecialTwo class="w-44 h-44 mr-16" />
          <div>
            <p class="text-white text-h4-bold mb-2">
              {{ $t('landing.what_is_special_section.block_two_title') }}
            </p>
            <p class="text-p-medium text-gray-800">
              {{ $t('landing.what_is_special_section.block_two_subtitle') }}
            </p>
          </div>
        </div>
        <div class="p-16 rounded-12 bg-gray-900 flex justify-start items-center mb-8">
          <IconSpecialThree class="w-44 h-44 mr-16" />
          <div>
            <p class="text-white text-h4-bold mb-2">
              {{ $t('landing.what_is_special_section.block_three_title') }}
            </p>
            <p class="text-p-medium text-gray-800">
              {{ $t('landing.what_is_special_section.block_three_subtitle') }}
            </p>
          </div>
        </div>
        <div class="p-16 rounded-12 bg-gray-900 flex justify-start items-center">
          <img
            class="w-44 h-44 mr-16"
            width="44"
            height="44"
            src="/landing/special-4.png"
            alt="1-to-1 Video chat"
            loading="lazy"
          />
          <div>
            <p class="text-white text-h4-bold mb-2">
              {{ $t('landing.what_is_special_section.block_fourth_title') }}
            </p>
            <p class="text-p-medium text-gray-800">
              {{ $t('landing.what_is_special_section.block_fourth_subtitle') }}
            </p>
          </div>
        </div>
      </section>

      <section class="px-20 py-32 mb-32">
        <h2 class="text-center gradient-text text-h1-bold mb-8">
          {{ $t('landing.get_started_section.title') }}
        </h2>
        <p class="text-center text-p-medium text-gray-800 mb-24 whitespace-pre-line">
          {{ $t('landing.get_started_section.subtitle') }}
        </p>
        <ButtonWhite
          size="44"
          class="flex justify-center items-center w-full"
          @click="$router.push(localePath(ROUTE_NAV.authWelcome))"
        >
          <IconCallChat class="mr-4" fill="#FF006E" />{{
            $t('landing.get_started_section.button')
          }}</ButtonWhite
        >
      </section>

      <footer>
        <FooterLinks class="mb-24" />
        <div class="text-center text-h8-regular text-[#9FA1B6] mb-32">
          <p>{{ $t('landing.address_first_part') }}</p>
          <p>{{ $t('landing.address_second_part') }}</p>
          <LinkToSupportEmail class="text-red" />
        </div>

        <div class="flex w-full justify-center items-center gap-12 pb-24">
          <div class="w-36 flex justify-center items-center h-24 rounded-4 bg-white/10">
            <IconJCB />
          </div>
          <div class="w-36 flex justify-center items-center h-24 rounded-4 bg-white/10">
            <IconMastercard class="!m-0" />
          </div>
          <div class="w-36 flex justify-center items-center h-24 rounded-4 bg-white/10">
            <IconVisa />
          </div>
          <div class="w-36 pt-4 flex justify-center items-center h-24 rounded-4 bg-white/10">
            <IconDiscover class="w-full h-full" />
          </div>
          <div class="w-36 flex justify-center items-center h-24 rounded-4 bg-white/10">
            <IconAmex />
          </div>
        </div>
      </footer>

      <CookiesAccessModal />
      <CookiesAccessTopbar />
    </div>
    <DownloadAppModal />
  </div>
</template>

<style lang="css">
.main-bg {
  background: #050505;
}

.download-button {
  background: linear-gradient(300deg, #bc4e9c, #f80759, #bc4e9c);
  background-size: 180% 180%;
  animation: gradient-animation 4s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 20%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
